<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="roleId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/role/filter.diti"
      :requestOptions="requestOptions"
    >
      <a-button type="primary" icon="plus" slot="action" @click="add"
        >新增</a-button
      >
    </PagingQueryPage>
    <a-modal
      :width="520"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="角色名称" prop="roleName">
          <a-input v-model="modalFormState.roleName" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage/diti.vue";
import { Modal, notification } from "ant-design-vue";
import { roleAdd, roleUpdate, roleDel } from "@/apis/role";
import { permissionFilter } from "@/apis/permission";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      queryFormState: {},
      requestOptions: {},
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "ID",
          dataIndex: "roleId",
          width: 200,
          align: "center",
        },
        {
          title: "角色名称",
          dataIndex: "roleName",
          width: 200,
          align: "center",
        },
        {
          title: "权限名称",
          dataIndex: "permissionIds",
          align: "center",
          customRender: (text) => {
            const idsArr = text && text !== "" ? text.split(",") : [];
            return idsArr.length > 0 ? this.formatePermissionIds(idsArr) : "\\";
          },
        },
        {
          title: "操作",
          dataIndex: "",
          width: 200,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  type="link"
                  onClick={() => {
                    this.edit(record);
                  }}
                >
                  编辑
                </a-button>
                <a-divider type="vertical" />
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
                <a-divider type="vertical" />
                <a-button
                  type="link"
                  onClick={() => {
                    this.editPermission(record);
                  }}
                >
                  权限
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "角色", // 弹窗标题
      modalRules: {
        roleName: [{ required: true, message: "名称必填", trigger: "blur" }],
      }, // 弹窗校验规则
      modalFormState: {
        roleId: "idGenerator",
        roleName: null,
      }, // 弹窗值
      permissionList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      permissionFilter({ numOfPage: "1", rowsInPage: "20" }).then((res) => {
        const {
          code,
          data: { resList },
        } = res?.data;
        if (code === "200") {
          this.permissionList = resList;
        }
      });
    },
    formatePermissionIds(arr) {
      const newArr = arr.map(
        (i) =>
          this.permissionList.find((o) => o.permissionId === i).permissionName
      );
      return newArr.join("、");
    },
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = null;
        }
      }
      this.modalFormState.roleId = "idGenerator";
    },
    edit(record) {
      this.modalVisible = true;
      this.isAdd = false;
      this.modalFormState.roleId = record.roleId;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = record[key];
        }
      }
    },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            roleAdd({
              ...this.requestOptions,
              ...this.modalFormState,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            roleUpdate({ ...this.modalFormState }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "编辑成功",
                });
              }
            });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          roleDel({ roleId: record.roleId }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
    editPermission() {
      this.$router.push({
        path: "/system/data/role/permission",
        // query: { roleId: record.roleId },
      });
    },
  },
};
</script>

<style></style>
